/**
 * Nosy Post Index
 *
 * @export PostIndex
 */

import {
    Box, Center, Container,
    Heading,
    LinkBox,
    LinkOverlay,
    SimpleGrid,
    Stack,
    Tag,
    useBreakpointValue
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import { graphql } from 'gatsby'
import React from 'react'
import Image from '../../components/image/image'
import Layout from '../../components/layout/layout'
import Link from '../../components/link/link'
import SEO from '../../components/seo/seo'
import { excerptSerializer } from '../../serializers'
import { mapEdgesToNodes } from '../../utils/helpers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_POST_INDEX_QUERY($ids: [String]) {
        allSanityPost(
            filter: { id: { in: $ids } }
            sort: { fields: publishedAt, order: DESC }
        ) {
            totalCount
            edges {
                node {
                    id
                    title
                    slug {
                        current
                    }
                    excerpt: _rawExcerpt(resolveReferences: { maxDepth: 10 })
                    body: _rawBody(resolveReferences: { maxDepth: 10 })
                    mainImage {
                        ...ImageWithPreview
                    }
                    categories: _rawCategories(
                        resolveReferences: { maxDepth: 10 }
                    )
                }
            }
        }
    }
`

const PostIndex = ( props ) => {
    const { data } = props
    // const posts = data.allSanityPost.edges.map( ( node ) => node )
    const posts = mapEdgesToNodes( data.allSanityPost )

    const imageWidths = useBreakpointValue( {
        base: 447, 
        sm: 356,
        md: 300,
        lg: 347
    } )

    return (
        <Layout>
            <SEO
                title='Blog'
                description='Blog description' />
            <Container maxWidth='6xl'>
                <Center flexDirection='column'>
                    <Heading 
                        as='h1'
                        maxWidth='5xl'
                        fontSize={['5xl', '4xl', '6xl', '7xl']}
                        textAlign='center' 
                        mt={12} 
                        mb={24}
                        fontWeight='extrabold'
                        color='gray.100'>
                        Blog
                    </Heading>
                </Center>
                
                <SimpleGrid my={20} columns={[1, 2, 3]} spacing={[4, 6, 8, 10]}>
                    {posts.map( ( { node } ) => (
                        <LinkBox 
                            key={node.id}
                            role='group'>
                            <Stack
                                direction='column'
                                spacing={8}
                                pb={20}
                                height='full'>
                                <Image
                                    imageData={node.mainImage}
                                    loading='eager'
                                    width={imageWidths}
                                    height={400}
                                    sx={{
                                        width: 'full'
                                    }} />
                                <LinkOverlay
                                    as={Link}
                                    to={`/blog/${node.slug.current}/`}>
                                    <Heading 
                                        as='h3'
                                        color='gray.100'
                                        transition='all 0.343s'
                                        _groupHover={{
                                            color: 'brand_secondary.500'
                                        }}>
                                        {node.title}
                                    </Heading>
                                </LinkOverlay>
                                <PortableText
                                    value={node.excerpt}
                                    components={excerptSerializer} />
                                <Box>
                                    {node.categories && (
                                        <Stack direction='row'>
                                            {node.categories
                                                .sort( ( a, b ) =>
                                                    a.title.localeCompare(
                                                        b.title
                                                    )
                                                )
                                                .map( ( category ) => (
                                                    <Tag
                                                        key={category._id}
                                                        color='gray.200'
                                                        backgroundColor='whiteAlpha.300'>
                                                        {category.title}
                                                    </Tag>
                                                ) )}
                                        </Stack>
                                    )}
                                </Box>
                            </Stack>
                        </LinkBox>
                    ) )}
                </SimpleGrid>
            </Container>
        </Layout>
    )
}

export default PostIndex
